"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const collectionReferences_1 = require("../../../../../../config/collectionReferences");
const Settings_1 = require("../../../../../../containers/Settings");
const User_1 = require("../../../../../../containers/User");
const styles = __importStar(require("../../../../View.modules.css"));
const icons_1 = require("@ant-design/icons");
const antd_1 = require("antd");
function PostItemBottomActionsBar(props) {
    var _a;
    const { postData, onToggleComments } = props;
    const { data: user } = User_1.useUser();
    const { t } = react_i18next_1.useTranslation();
    const [{ locale }] = Settings_1.useSettings();
    const isFollowing = (_a = postData === null || postData === void 0 ? void 0 : postData.followers) === null || _a === void 0 ? void 0 : _a.includes(user.id);
    const flexDirection = locale === 'ar' ? 'row-reverse' : 'row';
    const onFollowPress = react_1.useCallback(() => {
        var _a;
        const postRef = collectionReferences_1.communitySpacePostsCollectionRef.doc(postData.docId);
        const id = user === null || user === void 0 ? void 0 : user.id;
        if (!user) {
            return;
        }
        const newValue = lodash_1.uniq([...((_a = postData.followers) !== null && _a !== void 0 ? _a : []), id]);
        if (isFollowing) {
            postRef.update({ followers: newValue.filter(x => x !== id) });
        }
        else {
            postRef.update({ followers: newValue });
        }
    }, [isFollowing, postData.docId, postData.followers, user]);
    return (react_1.default.createElement("div", { className: styles.postItemBottomActionsContainer, style: { flexDirection } },
        react_1.default.createElement(antd_1.Button, { type: "text", className: styles.postAction, onClick: onFollowPress },
            isFollowing ? (react_1.default.createElement(icons_1.CheckCircleFilled, { style: { fontSize: 18 } })) : (react_1.default.createElement(icons_1.PlusCircleFilled, { style: { fontSize: 18 } })),
            react_1.default.createElement("p", { className: styles.actionText }, t(`app.communitySpace.${isFollowing ? 'followed' : 'follow'}`))),
        !postData.postCommentsDisabled ? (react_1.default.createElement(antd_1.Button, { type: "text", onClick: onToggleComments, className: styles.postAction },
            react_1.default.createElement(icons_1.CommentOutlined, { name: "comments", style: { fontSize: 18 } }),
            react_1.default.createElement("p", { className: styles.actionText }, t('app.communitySpace.comments')))) : null));
}
exports.default = react_1.default.memo(PostItemBottomActionsBar);
